import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Webcam from "react-webcam";
import "./KycForm.css";
import { useSearchParams } from "react-router-dom";
import Spinner from "./Spinner";

const KycForm = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token") || "No token provided";
  const email = searchParams.get("email") || "No email provided";

  // console.log(email,token);

  const [name, setName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [frontId, setFrontId] = useState(null);
  const [backId, setBackId] = useState(null);
  const [faceShot, setFaceShot] = useState(null);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const webcamRef = useRef(null);
  const [cameraAllowed, setCameraAllowed] = useState(null); // State to manage camera permission
  const [isLoading, setIsLoading] = useState(false);

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    // Add all other countries...
  ];

  // Check camera permission on component mount
  useEffect(() => {
    const checkCameraPermission = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraAllowed(true);
      } catch (err) {
        setCameraAllowed(false);
        setError(
          "Camera access is denied or unavailable. Please enable camera access in your browser settings."
        );
      }
    };

    checkCameraPermission();
  }, []);

  // This function is triggered when the user clicks the "Capture Image" button
  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setError(null);
        return imageSrc;
      } else {
        setError("Failed to capture screenshot");
      }
    } else {
      setError("Webcam not initialized");
    }
    return null;
  };

  const handleCaptureFrontId = () => {
    if (cameraAllowed) {
      const imageSrc = captureImage();
      setFrontId(imageSrc);
    } else {
      setError("Camera permission not granted. Please allow camera access.");
    }
  };

  const handleCaptureBackId = () => {
    if (cameraAllowed) {
      const imageSrc = captureImage();
      setBackId(imageSrc);
    } else {
      setError("Camera permission not granted. Please allow camera access.");
    }
  };

  const handleCaptureFaceShot = () => {
    if (cameraAllowed) {
      const imageSrc = captureImage();
      setFaceShot(imageSrc);
    } else {
      setError("Camera permission not granted. Please allow camera access.");
    }
  };

  const validateForm = () => {
    const errors = {};
    const phoneNumberPattern = /^\+?[0-9]{10,}$/;

    if (!name.trim()) errors.name = "Name is required";
    if (!phoneNumber.trim()) errors.phoneNumber = "Phone Number is required";
    if (!phoneNumberPattern.test(phoneNumber.trim()))
      errors.phoneNumber = "Phone Number must be at least 10 digits";
    if (!selectedCountry) errors.selectedCountry = "Please select the country";
    if (!addressLine1.trim())
      errors.addressLine1 =
        "Please enter your Street address, P.O.box, company name or C/O";
    if (!addressLine2.trim())
      errors.addressLine2 =
        "Please enter your Apartment, suite, unit, building, floor, etc.";
    if (!city.trim()) errors.city = "Please enter your city";
    if (!province.trim())
      errors.province = "Please enter your State/Province/Region";
    if (!zipCode.trim()) errors.zipCode = "Please enter your ZIP/POSTAL Code";

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    console.log(token);

    if (!validateForm()) return;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("addressLine1", addressLine1);
    formData.append("addressLine2", addressLine2);
    formData.append("city", city);
    formData.append("province", province);
    formData.append("zipCode", zipCode);
    formData.append("country", selectedCountry);
    formData.append("phoneNumber", phoneNumber);
    formData.append("email", email);

    console.log(
      name,
      addressLine1,
      addressLine2,
      city,
      province,
      zipCode,
      selectedCountry,
      phoneNumber
    );

    if (frontId) {
      // const frontIdBlob = await fetch(frontId).then((res) => res.blob());
      formData.append("frontId", frontId);
    }

    if (backId) {
      // const backIdBlob = await fetch(backId).then((res) => res.blob());
      formData.append("backId", backId);
    }

    if (faceShot) {
      // const faceShotBlob = await fetch(faceShot).then((res) => res.blob());
      console.log(faceShot);
      formData.append("faceShot", faceShot);
    }

    try {
      const response = await axios.post(
        "https://backend.nowtradefunded.com/api/users/submit-kyc",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,
          },
        }
      );
      alert(response.data.message);
      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message);
        setIsLoading(false);
      } else if (error.request) {
        alert("No response received from the server");
        setIsLoading(false);
      } else {
        alert("An error occurred: " + error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {isLoading ? <Spinner /> : null}
      <div className="kyc-form-container">
        <h1>KYC Form</h1>
        <form>
          <div className="form-group">
            <label>Full Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {formErrors.name && (
              <span className="error">{formErrors.name}</span>
            )}
          </div>
          <div className="form-group">
            <label>Address Line1:</label>
            <input
              type="text"
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
            />
            {formErrors.addressLine1 && (
              <span className="error">{formErrors.addressLine1}</span>
            )}
          </div>
          <div className="form-group">
            <label>Address Line2:</label>
            <input
              type="text"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
            />
            {formErrors.addressLine2 && (
              <span className="error">{formErrors.addressLine2}</span>
            )}
          </div>
          <div className="form-group">
            <label>City:</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            {formErrors.city && (
              <span className="error">{formErrors.city}</span>
            )}
          </div>
          <div className="form-group">
            <label>State/Province/Region:</label>
            <input
              type="text"
              value={province}
              onChange={(e) => setProvince(e.target.value)}
            />
            {formErrors.province && (
              <span className="error">{formErrors.province}</span>
            )}
          </div>
          <div className="form-group">
            <label>ZIP/Postal Code:</label>
            <input
              type="text"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
            {formErrors.zipCode && (
              <span className="error">{formErrors.zipCode}</span>
            )}
          </div>
          <div className="form-group">
            <label>Select a country:</label>
            <select
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
            >
              <option value="" disabled>
                Select a country
              </option>
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
            {formErrors.selectedCountry && (
              <span className="error">{formErrors.selectedCountry}</span>
            )}
          </div>
          <div className="form-group">
            <label>Phone Number:</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {formErrors.phoneNumber && (
              <span className="error">{formErrors.phoneNumber}</span>
            )}
          </div>
          <div className="form-group">
            <label>Capture ID (Front, Back, and Face Shot):</label>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/png"
              style={{ border: "solid gray 2px" }}
            />
            <div style={{ marginBottom: "10px" }}>
              <button type="button" onClick={handleCaptureFrontId}>
                Capture Front ID
              </button>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <button type="button" onClick={handleCaptureBackId}>
                Capture Back ID
              </button>
            </div>
            <button type="button" onClick={handleCaptureFaceShot}>
              Capture Face Shot
            </button>
            <div></div>

            {frontId && <img src={frontId} alt="Front ID" />}
            {backId && <img src={backId} alt="Back ID" />}
            {faceShot && <img src={faceShot} alt="Face Shot" />}
            {error && <span className="error">{error}</span>}
          </div>
          <button type="button" onClick={handleSubmit}>
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default KycForm;
